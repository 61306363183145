










import PaymentsService from "@/services/PaymentsService";
import Vue from "vue";
import Component from "vue-class-component";

@Component({ components: {} })
export default class GeneratePayment extends Vue {
  async createPayment() {
    const payment = await PaymentsService.generatePaymentForRegistration();
    window.location.href = payment.redirect_url as string;
  }

  created() {
    this.createPayment();
  }
}
